/** ------------------------------
    Fonts
    ------------------------------ */
@font-face {
  font-family: 'Inter';
  font-display: swap;
  font-weight: 400;
  src: url('Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  font-weight: 600;
  src: url('Inter-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Work Sans';
  font-display: swap;
  font-weight: 700;
  src: url('WorkSans-Bold.woff2') format('woff2');
}

/** ------------------------------
    Global
    ------------------------------ */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  background: #521f75 url('background.webp') no-repeat 50% 100%;
  background-size: cover;
  font: 100%/1.3 Inter, sans-serif;
  color: #fff;
}

@media (max-aspect-ratio: 1/1) {
  html {
    background-image: url('background-crop.webp');
  }
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

[ad-slot] {
  margin: auto;
  height: 50px;
}

@media (min-width: 728px) {
  [ad-slot] {
    height: 90px;
  }

  /* #root {
    height: calc(100% - 90px);
  } */
}

img {
  display: block;
  max-width: 100%;
}

button {
  appearance: none;
  border: 0;
  border-radius: none;
  background: transparent;
  font: inherit;
  color: #fff;
  cursor: pointer;
}

button > svg {
  fill: currentcolor;
}

/** ------------------------------
    Utilities
    ------------------------------ */
.align-center {
  text-align: center;
}

/** ------------------------------
    Game
    ------------------------------ */
.game {
  min-height: 100%;
  margin: auto;
  padding: 0 5px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.game__header {
  margin: 10px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game__header-brand-link {
  width: 60px;
}

@media (min-width: 500px) {
  .game__header-brand-link {
    width: 80px;
  }
}

.game__header-logo {
  margin: auto;
  width: 50%;
  max-width: 230px;
}

.game__header-actions {
  display: flex;
  align-items: center;
}

.game__header-actions > button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

.game__header-actions > button:hover > svg {
  fill: #b755ea;
}

.game__header-actions > :not(:first-child) {
  margin-left: 0.25em;
}

/** ------------------------------
    Grid
    ------------------------------ */
.grid {
  flex-grow: 1;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  width: calc(75vh - 200px);
  max-width: 100%;
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 5px;
}

.grid-row:not(:first-child) {
  margin-top: 5px;
}

.cell {
  border: 2px solid rgba(255, 255, 255, 0.15);
  background: #000718;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 2em;
  font-weight: 700;
}

.cell::before {
  content: '';
  display: inline-block;
  padding-bottom: 100%;
}

.cell--absent {
  background: #444;
}

.cell--correct {
  background: #105a26;
}

.cell--present {
  background: #be8f07;
}

.cell-animation {
  animation: revealCharCell linear;
  animation-duration: 0.15s;
}

@keyframes revealCharCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/** ------------------------------
    Keyboard
    ------------------------------ */
.keyboard {
  margin: 10px 0 5px;
}

.keyboard-row {
  display: flex;
  justify-content: center;
}

.keyboard-row:not(:first-child) {
  margin-top: 3px;
}

.key {
  margin-right: 3px;
  border-radius: 2px;
  background-color: #1d1c28;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 600;
  color: #fff;
}

.key:last-child {
  margin-right: 0;
}

.key:hover {
  transform: translateY(-1px);
  border-color: rgba(255, 255, 255, 0.2);
}

.key:focus {
  transform: translateY(1px);
}

.key--absent {
  opacity: 0.8;
  background: #333;
}

.key--correct {
  background: #105a26;
}

.key--present {
  background: #be8f07;
}

/** ------------------------------
    Toast
    ------------------------------ */
.toast {
  position: fixed;
  z-index: 50;
  left: 50%;
  bottom: 210px;
  transform: translateX(-50%);
  padding: 0.5em 1em;
  background-color: #b755ea;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.05);
  font-size: 1.25rem;
  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.034), 0px 0px 5.3px rgba(0, 0, 0, 0.048), 0px 0px 10px rgba(0, 0, 0, 0.06),
    0px 0px 17.9px rgba(0, 0, 0, 0.072), 0px 0px 33.4px rgba(0, 0, 0, 0.086), 0px 0px 80px rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

.toast--success {
  background-color: #105a26;
}

.toast--clipboard {
  background-color: #105a26;
  bottom: 30px;
  z-index: 9999;
}

.toast--warning {
  background-color: #dd403a;
}

/** ------------------------------
    Modal
    ------------------------------ */
.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__overlay {
  display: flex;
  min-height: 100%;
  padding: 2rem 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal__dialog {
  margin: auto;
  padding: 1rem;
  width: 95%;
  max-width: 500px;
  background-color: #2f145b;
  border-radius: 4px;
  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.034), 0px 0px 5.3px rgba(0, 0, 0, 0.048), 0px 0px 10px rgba(0, 0, 0, 0.06),
    0px 0px 17.9px rgba(0, 0, 0, 0.072), 0px 0px 33.4px rgba(0, 0, 0, 0.086), 0px 0px 80px rgba(0, 0, 0, 0.12);
  text-align: center;
}

.modal__dialog-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.modal__dialog-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.modal__dialog-close {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal__dialog-close:hover > svg {
  fill: #b755ea;
}

.modal__dialog-body {
  font-size: 0.925rem;
}

.modal__dialog-body p {
  margin: 0.75rem 0;
}

.modal__dialog-body h3 {
  margin: 1rem 0 0.5rem;
}

/** ------------------------------
    Stats
    ------------------------------ */
.stat-bar {
  display: flex;
  justify-content: space-between;
}

.stat-bar__item {
  text-align: center;
  padding: 0 0.5em;
}

.star-bar__item-label {
  font-size: 0.875em;
}

.stat-bar__item-value {
  font-size: 2rem;
  font-weight: 600;
}

/** ------------------------------
    Histogram
    ------------------------------ */
.histogram {
  font-size: 0.875rem;
  text-align: left;
}

.histogram__item {
  display: flex;
  line-height: 20px;
}

.histogram__item:not(:last-child) {
  margin-bottom: 4px;
}

.histogram__item-label {
  width: 2ch;
  text-align: center;
}

.histogram__item-bar {
  flex-grow: 1;
}

.histogram__item-bar > div {
  background-color: #b755ea;
  height: 100%;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 0.75rem;
  text-align: right;
  width: calc(20px + 1ch);
}

/** ------------------------------
    Button
    ------------------------------ */
.btn {
  display: inline-flex;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #b755ea;
  font-size: 1.25em;
  font-weight: 600;
  padding: 0 1em;
  line-height: 2.5em;
  border-radius: 4px;
  color: #fff;
}

.btn:hover {
  transform: scale(1.03);
}

.btn > svg {
  margin-right: 0.5em;
}

.btn + .btn {
  margin-left: 1em;
}

.pwa-install-btn {
  background-color: #e9c52d;
  color: #2f145b;
}

@media (display-mode: standalone) {
  .pwa-install-btn {
    display: none !important;
  }
}

.pwa-ios-prompt {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 190px;
  padding: 1em;
  border-radius: 4px;
  background-color: #b755ea;
  color: #fff;
  font-weight: 600;
}
.pwa-ios-prompt::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #b755ea;
}

.pwa-ios-prompt > svg {
  margin: 0 0.125em;
  fill: currentcolor;
  vertical-align: baseline;
}

/** ------------------------------
    Countdown
    ------------------------------ */
.countdown {
  display: block;
  margin: 0.25rem auto 1rem;
  font-size: 1.5rem;
}
